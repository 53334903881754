import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'



Vue.use(VueRouter)


const routes: Array<RouteConfig> = [
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/HomeView.vue'),
    redirect: '/home/page',
    //嵌套子路由
    children: [
      {
        path: '/home/page',
        name: 'page',
        component: () => import('../views/container/GamePage.vue'),
        meta: {
          roles: ["admin", "manger", "common"]
        }
      },
      {
        path: '/home/all',
        name: 'all',
        component: () => import('../views/container/GamesAll.vue'),
        meta: {
          roles: ["admin"]
        }
      },
      {
        path: '/home/tfData',
        name: 'tfData',
        component: () => import('../views/container/TfData.vue'),
        meta: {
          roles: ["admin", "common"]
        },
      },
      {
        path: '/home/ksData',
        name: 'ksData',
        component: () => import('../views/container/ksData.vue'),
        meta: {
          roles: ["admin", "common"]
        },
      },
      {
        path: '/home/ksCreate',
        name: 'ksCreate',
        component: () => import('../views/container/ksCreate.vue'),
        meta: {
          roles: ["admin", "common","shiPin"]
        },
      },
      {
        path: '/home/gamecon',
        name: 'gamecon',
        component: () => import('../views/container/GamesCon.vue'),
        meta: {
          roles: ["admin", "common"]
        }
      },
      {
        path: '/home/useradmin',
        name: 'useradmin',
        component: () => import('../views/container/useradmin.vue'),
        meta: {
          roles: ["admin"]
        }
      },
      {
        path: '/home/updatepsd',
        name: 'updatepsd',
        component: () => import('../views/container/updatepsd.vue'),
        meta: {
          roles: ["admin", "manger", "common"]
        }
      },
      {
        path: '/home/figuration',
        name: 'figuration',
        component: () => import('../views/container/figuration.vue'),
        meta: {
          roles: ["admin", "manger", "common"]
        }
      },
    ]
  },
  {
    path: '/login',
    name: 'LoginView',
    component: () => import('../views/login/index.vue')
  },
  {
    path: '/',
    redirect: '/login'
  }

];
const router = new VueRouter({
  routes
})

// to 到哪去
// from 从哪来
// next 是否放行 next() 放行 next('/login') 拦截到登录
// 如果准备去登录, 不需要拦截
// 如果已经登录过了, 有token, 不需要拦截
// 如果不是去登陆, 且没有 token, 拦截到登录页
router.beforeEach((to, from, next) => {
  const token = JSON.parse(localStorage.getItem('token') as string);
  // console.log(token);
  // console.log(to)
  const role = JSON.parse(localStorage.getItem('role') as string);
  if (to.path === '/login') {
    next()
  } else if (token) {
    if (to.meta!.roles.length !== 0) {
      for (let i = 0; i < to.meta!.roles.length; i++) {
        if (role === to.meta!.roles[i]) {
          next()
          break
        } else if (i === to.meta!.roles.length - 1) {
          alert("权限不足")
          break
        }
      }
    } else {
      next()
    }

  } else {
    next('/login')
  }
  // if (to.path === '/login' || token) {
  //   next()
  // } else {
  //   next('/login')
  // }
})



export default router
