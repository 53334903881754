import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';


Vue.config.productionTip = false;
// 引用axios依赖
import axios from 'axios'
// 将axios挂载到vue中，这里起名(随意)为request，在组件中就可以使用this.request来使用axios了
Vue.prototype.axios = axios
Vue.use(ElementUI);



// 配置axios的请求拦截器-(每次在请求头上携带后台分配的token-后台判断token是否有效等问题)
axios.interceptors.request.use(
  function(config) {
    // 在发送请求之前做些什么
    // console.log('请求到了哟', config.headers.Authorization)
    // 统一的给config设置 token
    config.headers.Authorization = JSON.parse(localStorage.getItem("token")as string);
    config.headers['Token'] = JSON.parse(localStorage.getItem("token") as string);
    return config;
  },
  function(error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);
//响应拦截器 与后端定义状态是100时候是错误 跳转到登录界面
axios.interceptors.response.use(function (response) {
  // 对响应数据做点什么
    //当返回信息为未登录或者登录失效的时候重定向为登录页面
  if (response.data.code == 401) {
    ElementUI.Notification({
      title: '警告',
      message: '登陆超时, 请重新登陆 ! ',
      type: 'warning',
    })
    router.push({
      path: '/login',
      replace:true
      // querry: { redirect: router.currentRoute.fullPath }//从哪个页面跳转
    }).catch(err=>{
      console.log(err);
      
    })
  }
  return response;
}, function (error) {
  // 对响应错误做点什么
  return Promise.reject(error)
})



new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
